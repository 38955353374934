<template>
  <div
    v-if="getHasContent"
    class="bp-richtext-container"
    :class="baseClass || 'text-body'"
  >
    <div itemprop="text">
      <RichTextRenderer
        :document="getMappedContent"
        :nodeRenderers="renderNodes()"
      />
    </div>
  </div>
</template>

<script>
import { INLINES, BLOCKS } from "@contentful/rich-text-types"
import RichTextRenderer from "contentful-rich-text-vue-renderer"

export default {
  props: {
    balance: Boolean,
    content: Object,
    baseClass: String,
    zdClasses: Boolean
  },

  components: {
    RichTextRenderer
  },

  methods: {
    customEmbeddedEntry(node, key, h) {
      const contentId = node.data && node.data.target && node.data.target.sys.id

      if (contentId) {
        let contentEntry = this.content.links.entries.block.find(
          entry => entry.sys.id === contentId
        )

        if (contentEntry) {
          const _type = contentEntry.__typename
          return h(_type, {
            key: key,
            props: { reference: contentEntry, inline: true }
          })
        }
      }
    },
    renderNodes() {
      return {
        [INLINES.HYPERLINK]: (node, key, h, next) => {
          if (
            (node.content &&
              node.content[0] &&
              node.content[0].value &&
              node.content[0].value.includes("@")) ||
            node.data.uri.includes("http")
          ) {
            return h(
              "a",
              { key: key, attrs: { href: node.data.uri, target: "_blank" } },
              next(node.content, key, h, next)
            )
          } else {
            return h(
              "router-link",
              { key: key, props: { to: node.data.uri } },
              next(node.content, key, h, next)
            )
          }
        },
        [BLOCKS.EMBEDDED_ENTRY]: this.customEmbeddedEntry
      }
    }
  },

  computed: {
    getMappedContent() {
      return this.content.json || this.content
    },

    getHasContent() {
      // TODO - this utter filth is because once a Rich Text field has had content entered and then deleted, Contentful returns a single 'empty' paragraph.
      // So we have to check whether there is more than a single paragraph, and if there isn't, check whether it's empty. I feel so dirty.
      // On the plus side, this should fix the promo banner height bug.
      return (
        this.getMappedContent &&
        this.getMappedContent.content &&
        (this.getMappedContent.content[0].nodeType == "embedded-entry-block" ||
          this.getMappedContent.content[0].content.length > 1 ||
          this.getMappedContent.content[0].content[0].value.length > 0)
      )
    }
  }
}
</script>

<style lang="scss">
// NB - This import solves for the Rich Text Section (for now)
// TODO - All text tags should inherit the updated style declarations by default in the global styles once the design stylesheet is ready, and then we can remove this import.
@import "@/styles/helpers/_help_typography.scss";

.bp-richtext-container {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 2em;
    margin-bottom: 0.5em;

    a {
      color: getcolour(bleach_peach);
    }

    &:first-child {
      margin-top: 0;
    }
  }

  p {
    // margin-top: 1em;
    margin-bottom: 1em;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    white-space: pre-line;
    // @extend .text-body;
  }

  h1 {
    @extend .text-editorial-giant;
  }

  h2 {
    @extend .text-editorial-heading-1;
  }

  h3 {
    @extend .text-editorial-heading-2;
  }

  h4 {
    @extend .text-editorial-heading-3;
  }

  h5 {
    @extend .text-editorial-intro;
  }

  li {
    margin-top: 0;
    margin-bottom: 0;
  }

  a {
    color: inherit;
    text-decoration: underline;
  }

  strong {
    font-weight: 700;
  }

  ul,
  ol {
    margin-top: 1.5em;
    margin-bottom: 3em;
    list-style-position: inside;

    @include media-breakpoint-up(md) {
      list-style-position: outside;
      padding-left: 1em;
    }
  }

  ul {
    li {
      margin-bottom: 1em;
      p,
      h1,
      h2,
      h3,
      h4,
      h5 {
        display: inline;
      }
    }
  }

  ol {
    counter-reset: section-counter 0;

    li {
      counter-increment: section-counter;
      margin-bottom: 1em;

      p,
      h1,
      h2,
      h3,
      h4,
      h5 {
        display: inline;
      }

      &::marker {
        content: counter(section-counter) ". ";
      }
    }
  }
}
</style>
